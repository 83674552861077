@charset "utf-8";

@import "variables";


//
@keyframes DRAW1 {
	0%{width: 0;}
	25%{width: 100%;}
	100%{width: 100%;}
}
@keyframes DRAW2 {
	0%{height: 0;}
	26%{height: 0;}
	50%{height: 100%;}
	100%{height: 100%;}
}
@keyframes DRAW3 {
	0%{width: 0;}
	51%{width: 0;}
	75%{width: 100%;}
	100%{width: 100%;}
}
@keyframes DRAW4 {
	0%{height: 0;}
	76%{height: 0;}
	100%{height: 100%;}
}

//
@keyframes bounce {
	0% {
		top: 3px;
	}
	5% {
		top: 0;
	}
	10% {
		top: -3px;
	}
	15% {
		top: 0;
	}
	100% {
		top: 0;
	}
}

//
@keyframes moveBg{
    0%    { background-position: 0 0; }
    100%  { background-position: 100vw 0; }
}


.key-basic{
	position: relative;
	top: 100px;
	opacity: 0;
	transition: top 1.5s ease,
				opacity 1.5s ease;
}
.key-basic.on{
	top: 0;
	opacity: 1;
}

//
.key-slide{
	position: relative;
	left: 100px;
	opacity: 0;
	transition: left 1.5s ease,
				opacity 2s ease;
}
.key-slide.on{
	left: 0;
	opacity: 1;
}

// key-fade
.key-fade{
	opacity: 0;
	transition: opacity 2s;
}
.key-fade.on{
	opacity: 1;
}

// key-curtain
.key-curtain{
	overflow: hidden;
	position: relative;
	span{
		line-height: 0;
		display: block;
		opacity: 0;
		position: relative;
		left: 100px;
		transition: left 1.5s;
	}
}
.key-curtain:before{
	content: '';
	opacity: 1;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: $baseColor;
	transform: translateX(-101%);
}
.key-curtain.on{
	span{
		left: 0;
		animation-name: curtainInner;
		animation-duration: 0s;
		animation-delay: .6s;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
	}
}
.key-curtain.on:before{
	animation-name: curtainStart,curtainEnd;
	animation-duration: .6s,.6s;
	animation-timing-function: cubic-bezier(.86,0,.07,1),cubic-bezier(.86,0,.07,1);
	animation-delay: 0s,.6s;
	animation-direction: alternate;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}
@keyframes curtainStart {
	0%{transform: translateX(-101%);}
	100%{transform: translateX(0);}
}
@keyframes curtainEnd {
	0%{transform: translateX(0);}
	100%{transform: translateX(101%);}
}
@keyframes curtainInner {
	0%{opacity: 0;}
	100%{opacity: 1;}
}